.OCVMapListDiv {
    overflow-y: scroll;
    max-height: 70vh;
}
.OCVMapListPaper {
    padding: 10px;
    margin: 10px;
}
.OCVMapListH6 {
    color: grey;
}