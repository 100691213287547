.OCVMapDetailViewPaper {
    padding: 10px;
    margin: 10px;
}
.OCVMapDetailViewH6 {
    color: grey;
}
.OCVMapDetailViewAnchor {
    padding: 0 5px;
}
.OCVMapDetailViewCol {
    text-align: left;
    padding: 10px 0;
}
.OCVMapDetailViewParagraph {
    padding: 15px 0;
}
.OCVMapDetailViewRow {
    justify-content: flex-start;
}
.OCVMapDetailLinks {
    color: black;
}
@media (max-width: 768px) {
    .OCVMapDetailLinks {
        color: #0056b3;
        text-decoration: underline;
    }
}