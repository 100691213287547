.OCVMapToolbarExternalDiv {
    box-shadow: 0 2px 2px -2px gray;
}
.OCVMapToolbarInternalDiv {
    margin: 10px 0;
    padding: 10px 0;
}
.OCVMapToolbarNestedDivOne {
    padding: 5px 0px;
    float: right;
}
.OCVMapToolbarButton {
    margin: 0px 5px;
}
.OCVMapToolbarColOne {
    background-color: #eee;
}
.OCVMapToolbarColTwo {
    padding: 5px;
}
.OCVMapToolbarNestedDivTwo {
    padding: 5px 0;
}